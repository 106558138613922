import React from 'react'

export const RefundPolicy = () => {
  return (
    <>
      <div className='container'>
        <div style={{ marginTop: '6rem' }}>
          <h1>Refund Policy & Cancellation Policy</h1>
        </div>
        <div>
          <ul>
            <li>
              1. A Trip extension request is to be made at least 1 hour before
              the dropoff time. Such requests are to be made to Customer care or
              totheField executive over the phone or through t h e
              Application/Website. Therequest will be confirmed or denied as per
              the availability of the Vehicle. In case the customer does not
              drop the bike in spite of a denied extensionrequest, penalty
              charges will be applicable.
            </li>
            <li>
              2. The customer is not authorized to lend the vehicle to any other
              person without prior intimation to Banjara Ride LLP. In such
              cases, Banjara Ride will first need to verify the documents of the
              additional riderbefore starting the trip.
            </li>
            <li>
              3. In case of minor damages to the helmet, the customer is liable
              to pay the fine as per vendor terms and conditions.
            </li>
            <li>
              4. In case of engine fault or failure, the customer needs to
              contact the vendor or Field Executive before getting any repairs
              done. The original printed invoice is mandatory to claim
              reimbursement for the same in case the repair is done by the
              customer.
            </li>
            <li>
              5. In case of theft, the customer is liable to pay, in full, the
              market rate of the vehicle under theft.
            </li>
            <li>
              6. For confirming the booking, the rider should pay the advance
              booking amount as well as Deposit otherwise booking will not be
              confirmed.
            </li>
            <li>
              7. 0-24 Hours before the start of the trip – The rent amount paid by
              the customer will be charged as a cancellation fee. There will be NO
              REFUND made for such cancellations
            </li>
            <li>
              8. More than 24 hours before the start of the trip – 50% of the rent
              amountpaid by the customer will be reduced as a cancellation fee and
              the remaining 50% of the rent amount will be refunded. The refunded
              rent amount will reflect in the customer's bank account or by cash
              within 7- 10 business days.
            </li>
            <li>
              9. No reimbursement/cancellation claim will be considered after 48
              hours of the completion of the booking period.
            </li>
            <li>
              10. Deposit will be refunded after 48 hours of the completion of the
              booking period.
            </li>
            <li>
              11. No refund for cancellation of bookings post advance payment.
            </li>
            <li>
              12. If any booking is canceled by Banjara Ride due to the
              non-availability of the vehicle, a full refund will be initiated. The
              refunded amount will reflect in the customer's bank account or by cash
              within 7-10 business days.
            </li>
            <li>
              13. Banjara Ride has the complete authority to cancel the booking
              without prior notice.
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
