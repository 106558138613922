import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
// import ButtonGroup from "../elements/ButtonGroup";
// import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-xl">
        <div className={innerClasses}>
          <div className="heroContainerCustom">
            <div className="hero-content mt-16 pt-12 custom-hero-section">
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                Bhopal's Leading Two Wheeler{" "}
                <span className="text-color-primary">Rental Service</span>
              </h1>
              <div className="container-xs">
                <p
                  className="m-0 mb-32 reveal-from-bottom"
                  data-reveal-delay="400"
                >
                  BanjaraRide is Bhopal based Automobile Renting Enterprise where we provide travelling solutions from wide range of Motorbikes and Scooters.
                </p>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                  <div className='download-box'>
                    <Image
                      src={require("./../../assets/images/playstore.png")}
                      alt="Open"
                      width={220}
                      height={220}
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.ai.graeon.banjararide','_blank')}
                    />
                    {/* <Image
                      src={require("./../../assets/images/applesoon.png")}
                      alt="Open"
                      width={180}
                      height={180}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hero-figure reveal-from-bottom illustration-element-01 mt-6 mobile-icon-hero"
              data-reveal-value="20px"
              data-reveal-delay="800"
            >
              <a
                data-video="https://www.youtube.com/embed/7oYGqwYh5E0"
                href="#0"
                aria-controls="video-modal"
                onClick={openModal}
              >
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/group.png")}
                  alt="Hero"
                  autoplay
                  width={520}
                  height={100}
                />
              </a>
            </div>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            autoplay
            video="https://www.youtube.com/embed/7oYGqwYh5E0"
            videoTag="iframe"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
