import React from "react";

export const TermsAndConditions = () => {
  return (
    <>
      <div className="container">
        <div style={{ marginTop: "6rem" }}>
          <h1>
            TERMS AND CONDITIONS FOR END USERS OF THE SERVICES PROVIDED BY
            BANJARA RIDE LLP
          </h1>
        </div>
        <div>
          BANJARA RIDE LLP (herein after referred to as "Banjara Ride") provides
          online, as well as offline rental services for booking of two-wheeler
          vehicles (Hereinafter referred to a “Ride”) to you (hereinafter
          referred to as "You" or "End User") by means of the Banjara Ride'
          website, mobile application, and also through offline means viz: on
          spot or on call booking (which will collectively be referred to as
          "Service Platform"). Service platform will be the means of providing
          services to you. Through utilising the services offered by Banjara
          Ride you also explicitly agree that the association between you and
          Banjara Ride will be strictly governed by the present terms and
          conditions. It is your responsibility to carefully read all the
          conditions mentioned in the present document before utilising the
          services of Banja Ride through any service platform. When you utilise
          the services offered by Banjara Ride, it will only be after you have
          gone through the present terms and conditions and agree to the same at
          all levels. In case you disagree with any term or condition mentioned
          herein, please do not utilise the services offered by Banjara Ride.
          Any challenge to the present terms and conditions will not be accepted
          at any stage. CLAUSE I DEFINITIONS 1. SERVICES: The Service Platform
          of Banjara Ride offers the primary service of renting/leasing out
          motorcycle/motorbike/scooter or other two- wheelers vehicle which may
          be directly/indirectly owned by Banjara Ride, or owned by a
          partner/lessor to Customers across the state of Madhya Pradesh, India.
          ("Services"). <br /> 2. BOOKING AMOUNT: Booking Amount is the
          reservation fee collected by Banjara Ride for reserving the vehicle at
          the time of booking. The Booking Amount shall be paid directly to
          Banjara Ride on Banjara Ride Platform. Booking amount = deposit + rent
          amount 3. RENTAL CHARGES: The rental fees offered by Banjara Ride is
          as per shown on the Banjara Ride Rental website/app which shall be
          paid to the Banjara ides directly by You. It may or may not include
          the Booking Amount. Banjara Ride reserves the right to change the
          rental rate as per its own discretion. Banjara Ride will make
          reasonable efforts to reflect all the latest rental rates. 4. SECURITY
          DEPOSIT: You will be compulsorily required to make a security deposit
          at the time of Vehicle Pickup. You shall pay such a refundable
          security deposit directly to Banjara Ride. Banjara Ride may deduct an
          amount from the security deposit or may retain the entire security
          deposit in case damage has been caused to the Vehicle, late return of
          the Vehicle, cost in relation to any traffic challans or traffic rule
          violations, in the event of any Booking extensions or in case of fuel
          deficit. Security deposit will be refunded after 24 hours by any
          payment mode as decided by Banjara Ride. <br /> CLAUSE II TERMS OF
          UTILISATION OF THE VEHICLE <br /> 1. Particulars of the end user: The
          User/customer who utilises the services of Banjara Ride will have to
          provide all and any personal information requested by Banjara Ride.
          Specifically, you will be required to provide your full name,
          operational phone number(s), alternate telephone number(s), Present
          and Permanent residential address, office address and email address.
          It is compulsory for the user to hold a valid and effective Driving
          Licence to rent out vehicles and a selfie verification of documents
          will also be done by Banjara Ride before the same. The services of
          Banjar Ride will only and strictly be utilised by end users who have
          attained the age of 18 at the time of booking. <br />
          2. Objective for renting: You agree to truthfully elaborate the
          duration for renting the Vehicle and the reason for leasing/renting
          out the Vehicle. The Vehicle can be rented out only for personal
          purposes. In case you want to rent the vehicle for any commercial or
          advanced purpose other than personal travel, you must disclose the
          same to Banjara Ride and separate terms and conditions will be charted
          out for such use. 3. Truthful Disclosure: You are under a direct,
          strict and irrevocable legal obligation that every piece of
          information provided by you to Banjara Ride, either before or during,
          the usage of Services offered by Banjara Ride and during any other
          point of time shall be true, correct, complete and accurate in all
          respects. Banjara Ride is at the liberty to impose a penalty upon you
          if it is found that any information provided to Banjara Ride was not
          true. Banjara Ride will also initiate legal proceedings against you if
          it deems fit under the circumstances. 4. Banjara Ride shall have the
          sole discretion to accept or reject each booking for the Service. 5.
          End user/You must possess a valid and effective Motor Vehicle with
          Gear (MVWG) License in case of Motor Vehicles with Gear and Motor
          Vehicle without Gear (MVWOG) License in case of Motor Vehicles without
          Gear. A copy of your Valid and effective driving Licence will be
          submitted with Banjara Ride. If in a certain case your license expires
          or is revoked by the respective authority, the booking will stand
          cancelled suo motu. 6. If in case you are a foreign national living in
          India, a valid and effective driving license from your home country
          with International Driving Permit has to be submitted, or local
          licence if obtained. If in case you are a foreign national visiting
          India, then, a valid and effective driving license from their home
          country with International Driving Permit is necessary along with a
          valid and effective Visa and a copy of your passport must be
          submitted. 7. All calls Made to Banjara Ride for booking or other
          purposes will be monitored and may be recorded at the discretion of
          Banjara Ride for training or other purposes. Such recordings may also
          be used in a court of law as evidence. 8. A booking made by you will
          not bring upon any legal rights until the same is confirmed by Banjara
          Ride. Booking Confirmation will be sent on your registered email
          address and mobile number. 9. All reasonable efforts will be made by
          Banjara Ride to ensure that the vehicle demanded by you is available
          for rent. In case the vehicle is not available due to certain
          unforeseen circumstances Banjara Ride may cancel the booking and the
          amount paid therein shall be refunded subject to the terms of the
          present agreement. 10. Banjara Ride may discontinue or introduce any
          new platform for booking Vehicles and/or for providing Services. 11.
          At all times, during, before and after the booking, it shall be your
          legal responsibility to abstain from doing, or committing, any such
          act which Banjara Ride might reasonably conclude, to be disreputable
          or capable of damaging the reputation of Banjara Ride. 12. You will
          comply with all the regulations prevalent in the Republic of India and
          the State of Madhya Pradesh. By utilising the services of Banjara
          Ride, you agree that you have read and understood all the provisions
          enumerated under the Motor Vehicles Act, 1988 and the Motor Vehicle
          rules of Madhya Pradesh. 13. In order to make a valid booking of any
          vehicle, you will have to submit the following, but not limited to the
          list, documents: a. Original Driving License b. Original Aadhar Card/
          PAN/ Passport c. Passport and visa – for foreign nationals Kindly
          Note: You must carry the original document at the time of pick-up,
          which will be verified by Banjara Ride and a photocopy of the same
          will be retained for future use. 14. A helmet may be provided by
          Banjara Ride with the Vehicle as per availability. Banjara Ride does
          not provide a guarantee that a helmet will be provided. If a helmet is
          provided by Banjara Ride its charges will be included in the rentals
          of the vehicle. Helmets for you as well as for pillion are a mandatory
          requirement by law and hence you shall ensure that the pillion rider
          is wearing a Helmet during the use of the Vehicle as per the traffic
          rules applicable. The User has the responsibility to follow the law
          related to wearing of helmet for the rider as well as the pillion, and
          is liable to pay any fines/costs that are incurred due to violation of
          such law. A fine may also be imposed on the user by Banjara Ride for
          Violating the Traffic rules laid down by the Respective Statutes. 15.
          In cases where a helmet is provided by Banjara Ride and it is found
          upon return delivery that the helmet is damaged you shall be liable to
          pay a fine, as imposed by Banjara Ride. 16. The Cost of Fuel will be
          borne by the user Entirely. Banjara Ride is not liable or responsible
          for the Vehicle fuel consumption and mileage. 17. Any change in the
          Pick-up date, time and location of a confirmed booking may be changed
          subject to confirmation by Banjara Ride and applicable rescheduling
          fees shall be payable by you. No modification in the confirmed booking
          will be made by Banjara Ride on the website. 18. The User is not
          authorized to sub-lend or sell the two-wheeler to any person and such
          action will lead to imposition of a fine or initiation of legal action
          against the end user. 19. In case of Mechanical
          failure/breakdown/cleanliness/maintenance during the ride, you have to
          immediately intimate Banjara Ride about the same and the location and
          kind of defect has to be shared along with videos and pictures.
          Banjara Ride may take suitable steps as per their internal policy. 20.
          It Shall be your responsibility to collect any Vehicle related
          document including but not limited to registration certificate,
          insurance, PUC from Banjara Ride. Banjara Ride will not be held liable
          if the aforesaid documents are not provided to you. Banjara Ride may
          also provide you with soft copies of the aforesaid documents. 21. It
          is a very strict condition that the vehicle will be plied only within
          the permitted area of usage which shall be declared at the time of
          pick up. The vehicle can either be plied within the boundaries of a
          city/district or within the boundaries of a state.
          Intercity/interstate travel on the rented vehicle is not allowed
          unless prior and explicit permission is obtained from Banjara Ride. If
          this condition is violated heavy Fine will be imposed upon the user.
          The road tax payable during intercity/interstate travel will be paid
          by the user only. 22. Tracking of the Vehicle: Vehicle may be tracked
          with a GPS tracker by Banjara Ride. Banjara Ride ensures that your
          privacy is not compromised in any way. The information regarding the
          tracking of the Vehicle shall only be shared with the relevant
          authorities in case of theft/accident/or any other incident which
          happens with the Vehicle. 23. It Shall be your responsibility to
          inspect the Vehicle before riding them for any physical or noticeable
          mechanical defects or flaw found on/in the vehicle. You have to bear
          all the cost of replacement pertaining to any damage to the vehicle
          loss of tools and spare parts kept in the vehicle. Any type of damage
          happened to the behicle during the ride should be intimated to Banjara
          Ride while returning the vehicle. CLAUSE III CONSIDERATIONS 1. You
          shall be required to pay Booking Amount for booking the Vehicle to
          Banjara Ride on Banjara Ride Platform. In order to ensure that the
          booking is confirmed you must make successful payment of amount. If a
          successful payment of the booking amount is not made, then the booking
          will not be confirmed. Vehicle selection will be on the basis of first
          come first serve.
          <div>
            2. If a proper cancellation is done by you before 24 hours of the
            decided pick up time, then 50% of the rent amount paid by the
            customer will be deducted as cancellation fee, and the remaining 50%
            of the rent amount will be refunded. The refunded rent amount will
            reflect in the customer's bank account or by cash within 7-10
            business days. 3. If in case a booking is cancelled by Banjara Ride
            due to non-availability of vehicle or due to some other unforeseen
            circumstances, a complete refund will be initiated. The refunded
            amount will reflect in the customer's bank account or by cash within
            7-10 business days. 4. Banjara Ride reserves the complete and
            irrevocable right to cancel any booking without any prior notice at
            any point of time. It is not mandatory that a reason will be stated
            or explained to you at the time of cancellation or thereafter. 5.
            Once a booking has been confirmed by you and a time period of 48
            hours has passed since then, no refund/reimbursement/cancellations
            claim will be entertained and the entire amount will be treated as
            cancellation charge. 6. No refund claim will be entertained once the
            actual booking duration of trip has started. CLAUSE V OTHER TERMS 1.
            It shall be your responsibility to secure the Vehicle during the
            booking period. The ignition of the Vehicle must be turned off and
            the handle must be locked properly. Penalty will be imposed on you
            if this code of conduct is not adhered to. 2. Banjara Ride
            undertakes the responsibility to perform all necessary and required
            routine maintenance on all of its Vehicles. However, you shall be
            responsible for the cleanliness of the Vehicle and are expected to
            assist in helping Banjara Ride to maintain the driving safety and
            performance of the vehicle, in particular during longer bookings or
            when the vehicle indicates that service or maintenance is required.
            3. If in case the vehicle breaks down, it must be reported to the
            Banjara Ride immediately without any delay. 4. You must return the
            Vehicle, secured, clean, and in good working order to Banjara Ride
            at the end of the booking duration. 5. In case a helmet(s) is
            provided to you by Banjara Ride, it shall be your responsibility to
            return the same in a clean state and good working condition. If the
            Helmet has suffered any damage, you will be liable to pay the cost
            of the Helmet. 6. If in case you observe certain abnormality in a
            vehicle, which may or may not impair the ride, it shall be your
            responsibility to report the same to Banjara Ride and also to not
            ride the vehicle in such a condition. The vehicle will be replaced,
            depending on availability. 7. If there is certain damage or
            abnormality in the vehicle, you must bring that to the notice of
            banjara Ride before taking the vehicle. If in case a problem or
            damage was not notified to Banjara Ride at the time of taking the
            vehicle but the same appears at the time of return, you shall be
            deemed responsible for the same. 8. You are solely and irrevocably
            responsible for the Vehicle while driving and any damage caused to
            it during the trip. 9. In case of occurrence of theft of vehicle,
            the same must be immediately reported to Banjara Ride and to the
            nearest police station with all the details of the theft truthfully.
            You will be the primary witness of such theft and it shall be your
            duty to provide Banjara Ride and the Police authorities with the
            factual details of the event. You must fully cooperate with Banjara
            Ride and police authorities. 10. If in case a summons, complaint,
            document, or notice of any nature is received by you in relation to
            an accident, theft, or other circumstances related to the Vehicle
            rented by you, you must immediately inform Banjara Ride and provide
            a copy of the said summons, complaint, document, or notice. 11. You
            must never leave a vehicle in a zone which has parking restrictions.
            If you have left the Vehicle in such a restricted zone, you must
            immediately notify Banjara Ride and you will be responsible for any
            and all violation notices or towing charges incurred by Banjara
            Ride. 12. The use of the vehicle must be done in a normal and
            careful manner. In case the vehicle is used which causes excessive
            wear and tear you will be liable to pay a penalty. 13. You are not
            allowed to attach any external fixtures or fittings or upgrades to
            the Vehicle. No repairs, replacement of any item of the Vehicle will
            be done by you. 14. In case of accident of the Vehicle, the cost of
            towing the vehicle from the place of accident to the service station
            and all other amounts which includes damage to the property of third
            party and compensation required to be paid to the victim/third party
            have to be paid by you. You will compensate the Banjara Ride for all
            damages caused to the Vehicle. A daily tariff will also be paid by
            you for the amount of time for which the vehicle stays in the
            service station. 15. Banjara Ride will inform you if in case a
            vehicle is unavailable after a booking has been made by you.
            However, in certain cases the reason of unavailability may be beyond
            the control of Banjara Ride and in such cases a settlement will be
            reached between you and Banjara Ride which shall solely depend on
            Banjara Ride’s discretion. CLAUSE VI PROHIBITED USE It is a clearly
            agreed term of booking that the rented vehicle will not be used by
            you for the below mentioned purposes under any circumstance. In case
            it is found that the vehicle was used for any of the below mentioned
            purposes or in the below mentioned manners, Banjara Ride will report
            the same to the respective authorities and you shall also be liable
            to pay a penalty as decided by Banjara Ride. The below-mentioned
            list is not exhaustive, and any unreasonable or inappropriate use of
            the Vehicle provided by the Banjara Ride, as determined by Banjara
            Ride in its sole discretion, may be deemed a violation of these
            Terms of Use. 1. The vehicle will not be plied by any person who is
            under the influence of alcohol or any drug or medication under the
            effects of which the operation of a vehicle is prohibited or not
            recommended. A zero-tolerance policy is adopted in such cases. 2. In
            the carrying out any illegal activity. 3. The transportation or
            carrying of any arms of illegal substances is strictly prohibited.
            4. For Speeding or entering a racing competition whether legal or
            illegal. 5. The vehicle will not be used in an imprudent, negligent,
            or abusive manner or for any use which can be considered abnormal in
            the opinion of Banjara Ride. 6. The Vehicle will not be used by any
            person who has provided Banjara Ride and/ or any employee of Banjara
            Ride with false information or whose representations are determined
            to be false (including, without limitation, regarding his name, age,
            or address). 7. The Vehicle will not be used for driving outside of
            the permitted area or any prohibited areas within the permitted
            area. 8. The Vehicle will not be used for driving while using a
            mobile phone or any other mobile communication device in any manner
            whatsoever, including while sending an SMS, emailing, using a cell
            phone with or without a hands-free device, otherwise using a mobile
            communication device that may distract You from driving or otherwise
            engaging in similar activities that may be prohibited by applicable
            law. 9. The Vehicle will not be used to transport flammable,
            poisonous, or otherwise hazardous substances for other than legally
            permissible household use or in amounts greater than what is usually
            consumed in a household. 10. The Vehicle will not be used for
            transporting objects on the vehicle that could adversely impact the
            Vehicle's handling safety or that could damage the Vehicle or, by
            virtue of such object/s protruding outside, affect the safety of
            vehicles driving in the vicinity of the Vehicle. 11. The Vehicle
            will not be used for carrying more than 1 passenger. 12. Only the
            prescribed fuel for the selected model will be filled in the
            vehicle. 13. The general rules of riding must be studied by you
            before the trip. You shall follow speed limitation, traffic rules or
            directions of the police or other enforcement agencies. If there is
            any violation of a traffic rule or any other law such as, over
            speeding, jumping a signal, riding with two pillions, riding without
            a helmet, parking illegally etc., the User has to pay all the
            fines/penalties. If any case is registered by the police authorities
            against Banjara Ride, being the owner or permit holder of the
            vehicle, you have to compensate Banjara Ride all costs, fines and
            legal fees incurred. 14. If it is found that you ride the vehicle
            beyond the speed limit set as per the traffic rules or any other
            laws, Banjara Ride will not protect you from any loss or damage to
            the vehicle, and the third party through the insurance that it has,
            and you shall personally be liable for all the loss or damages
            caused. Kindly note - The contract of usage would be terminated
            immediately and heavy penalty will be imposed upon you due to the
            violation of any of these Terms of Use and you are required to
            return the Vehicle immediately back to Banjara Ride. There would be
            no refund of the Rental Charges already made, and you have sole
            responsibility for any and all costs, charges, fees and expenses
            incurred by Banjara Ride as a result of a breach of these
            Conditions. CLAUSE VII TRIP EXTENSION 1. If in case you wish to
            extend the duration of your trip, a request has to be made to
            Banjara Ride at least 1 hour prior to the decided time of drop off.
            You will have to make the request for extension of time of trip the
            customer care/ Field executive of Banjara Ride over the phone. Trip
            can also be extended through the android application of Banjara Ride
            but the same will also be subject to acceptance. As per the booking
            schedule, Banjara Ride reserves the right to confirm or deny the
            request depending on the bike availability. 2. Charges for Trip
            extension: The standard rates will be applicable for extensions. 3.
            If a request for trip extension has been made by you but the same
            has not been actively denied or accepted, it will be deemed that the
            request is denied. You will not be allowed to extend the trip and
            penalty shall be imposed upon you subject to the terms of the
            present agreement. 4. If in case you don’t drop the Vehicle even
            when the extension request was denied, penalty charges will be
            applicable. If you suspect that you will be late, please reach out
            to the Banjara Ride to avoid the penalty. CLAUSE VIII NO ADMISSION
            OF LIABILITY 1. User agrees that neither the partners of Banjara
            Ride nor officers or employee shall be liable for any direct or/and
            indirect or/and incidental or/and special or/and consequential
            or/and exemplary damages, resulting from (i) the use or/and the
            inability to use the service; (ii) any claim against damage which
            may happen to the User during the booking; (iii) any inconvenience
            to the User arising out of any changes made by Banjara Ride in the
            booking such as Vehicle type, timings of booking or condition of the
            vehicle etc; (iv) any act of omission and/ or commission of fraud/
            theft/ willful misconduct or gross negligence by the User; (v)
            non-compliance of traffic rules by the User and any liability/ fine
            or penalties arising out of such non-compliance. 2. In no event
            shall Banjara Ride's total liability to you for all damages or/and
            losses or/and causes of action exceed the amount paid by you to the
            Banjara Ride, if any. 3. Banjara Ride is not responsible for any
            damage to, loss or theft of, any personal property belonging to you,
            regardless of fault or negligence. 4. The information,
            recommendations provided to you on or through the website, or the
            Platform is for general information purposes only and does not
            constitute advice. 5. Banjara Ride will reasonably keep the website
            and the application and its contents correct and up to date but does
            not guarantee that the website and/or application are free of
            errors, defects, malware and viruses or that the website and/or
            application are correct, up to date and accurate. Banjara Ride shall
            not be liable for any damage arising from the same. 6. Banjara Ride
            shall further not be liable for damages resulting from the use of or
            the inability to use the website or the application, including - but
            not limited to - damages resulting from failure or delay in delivery
            of electronic communications, interception or manipulation of
            electronic communications by third parties or by computer programs
            used for electronic communications and transmission of viruses.
            CLAUSE IX INTELLECTUAL PROPERTY RIGHTS 1. Banjara Ride is the sole
            owner and lawful licensee of all the rights to the web site,
            Platform or any other digital media and its contents. The content
            means its design, layout, text, images, graphics, sounds, video,
            etc. the website, Platform or any other digital media content embody
            trade secrets and intellectual property rights protected under
            worldwide copyright and other laws. All titles, ownership and
            intellectual property rights in the website and its content shall
            remain with Banjara Ride, its affiliates, agents, authorized
            representatives, as the case may be. 2. All rights not otherwise
            claimed under this Terms of Use or by the Banjara Ride are hereby
            reserved. The information contained in this Platform and/or website
            is intended, solely to provide general information for the personal
            use of the reader, who accepts full responsibility for its use. 3.
            Banjara Ride does not represent or endorse the accuracy or
            reliability of any information or advertisement contained on,
            distributed through, or linked, downloaded or accessed from any of
            the services contained on this website or Platform, or the quality
            of any products, information or other materials displayed, or
            obtained by you as a result of any product, information or other
            materials displayed, or obtained by you as a result of an
            advertisement or any other information or offer in or in connection
            with the service. 4. All related icons and logos are trademarks or
            service marks or word marks of the Banjara Ride in various
            jurisdictions and are protected under applicable copyrights,
            trademarks and other proprietary rights laws. The unauthorized
            copying, modification, use or publication of these marks is strictly
            prohibited. 5. All the contents on this website and/or Platform is
            copyright of the Banjara Ride except the third-party content and
            link to third party website on our website and/or Platform. 6. You
            shall not do the following: I. license, sublicense, sell, resell,
            transfer, assign, distribute or otherwise commercially exploit or
            make available to any third party the Platform in any way; II.
            modify or make derivative works based upon the Platform; III. create
            Internet "links" or "frame" or "mirror" any application on any other
            server or wireless or Internet-based device; IV. reverse engineer or
            access the Platform in order to: V. design or build a competitive
            product or service, VI. design or build a product using similar
            ideas, features, functions or graphics of the Platform, or VII. copy
            any ideas, features, functions or graphics of the Platform, or VIII.
            launch an automated program or script, including, but not limited
            to, web spiders, web crawlers, web robots, web ants, web indexers,
            bots, viruses or worms, or any program which may make multiple
            server requests per second, or unduly burdens or hinders the
            operation and/or performance of the Platform. CLAUSE X INDEMNITY You
            indemnify and hold Banjara Ride, and their respective Partners,
            officers, employees, shareholders, agents, attorneys, assigns and
            successors-in-interest, harmless for all losses, liabilities,
            damages, injuries, claims, demands, costs, attorney fees, and other
            expenses incurred by you due to the use or possession of the Vehicle
            by you, violation of any applicable laws, and any non-compliance
            with these Terms and conditions. CLAUSE XI GOVERNING LAW &
            JURISDICTION 1. The terms and conditions and any dispute arising out
            of the same shall be adjudicated by, and interpreted in all regard
            in accordance with the laws of the Republic of India. 2. You submit
            hereby to the exclusive jurisdiction of the courts of Bhopal, Madhya
            Pradesh, India. 3. All disputes arising out of or in relation to
            these Terms of Use shall be settled amicably by the Parties. In the
            event no amicable settlement is arrived at within a period of
            fifteen (15) days from the date of first initiation of the dispute
            by one Party to other, the Parties shall resolve the dispute by
            means of arbitration pursuant to the Arbitration and Conciliation
            Act, 1996. 4. The arbitration proceedings shall be conducted by an
            arbitral tribunal comprising of 1 (one) arbitrator mutually
            appointed by you and Banjara Ride. 5. The arbitration proceedings
            shall be conducted in English language only and the seat for
            arbitration shall be Bhopal, Madhya Pradesh, India. 6. The award of
            the arbitral tribunal shall be final and binding. CLAUSE XII NO
            AGENCY The End use of the vehicle will, under no circumstances, be
            considered an agent of banjara Ride for any purpose and before any
            authority whatsoever. CLAUSE XIII ASSIGNMENT Any rights under the
            present terms and conditions cannot be assigned to a third party
            without the prior written consent of Banjara Ride. CLAUSE XIV
            AMENDMENT The present terms and conditions may be amended by Banjara
            Ride at any time without a prior notice and the same shall become
            applicable as notified by Banjara Ride. The Amendment and its
            applicability will be on the sole discretion of Banjara Ride. CLAUSE
            XV SEVERABILITY If any provision or any part of a provision of the
            present Terms of and conditions is found to be non-effective,
            unenforceable or prohibited by applicable laws of the Republic of
            India, in that case any such provision or part of provision shall be
            severed from these Terms and conditions and shall be considered
            divisible as to such provision or part thereof and such provision or
            part thereof shall not be in action and shall form a part of the
            agreement between you and Banjara Ride hereto and the remaining of
            these Terms of Use shall be valid and binding and have an effect as
            though the said provision was not a part of the present agreement.
            Kindly note that the present document will be considered an
            electronic record according to the definition prescribed in the
            information technology act, 2000 and rules thereunder as applicable,
            also under other laws, either central or state, as amended by the
            information technology act, 2000. More Apps Mail Merge Send neat
            templates to multiple recipients with personalized values Zoho
            Projects Task or Bug ? Add from email! Manage Projects right within
            your Inbox. Zoho Desk Better insights for handling customers with
            relevant ticket history. PGP PGP or Pretty Good Privacy, secures
            email communications with end-to-end encryption. ServiceDesk Plus
            Cloud Bring in your IT help desk right within your Inbox. Submit a
            request or check the ticket status. You can also search or lookup
            ticket details directly. Jira Easily create issues and track bugs
            directly from your emails. Zoom Launch a quick Zoom meeting from
            inside your mailbox. Trello Work efficiently with Trello cards right
            next to your emails. HubSpot Get contextual insights about your
            sales contacts right from the Inbox. Asana Turn your emails into
            tasks and be up to date on your projects. Zendesk Get an overview of
            your customer tickets and manage them from inside your inbox.
            Evernote Create notes contextually from emails. Add them quickly to
            emails you send. FullContact Get insights about your contact's job
            title, location and social profile. Clearbit Get overall information
            about your contacts based on their social profiles. Streams Banjara
            rides terms and conditions Final .pdf Mail Mail Here is your Smart
            Chat (Ctrl+Space)
          </div>
        </div>
        <div>
          <button className="download-pdf-btn" onClick={() => window.open('https://drive.google.com/file/d/17ZGEHxb_Kq_vkDW3Tkkmzi0cCsOr3T8X/view?usp=sharing')}>View PDF</button>
        </div>
      </div>
    </>
  );
};
