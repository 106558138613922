import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import { PrivacyPolicy } from './pages/privacy-policy';
import { TermsAndConditions } from './pages/terms-condition';
import { RefundPolicy } from './pages/cancel-refund';
import { AboutUs } from './pages/about-us';
import { ContactUs } from './pages/contact-us';
import { LayoutSubPages } from './layouts/LayoutSubPages';

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/privacy-policy" component={PrivacyPolicy} layout={LayoutSubPages} />
          <AppRoute exact path="/terms&conditions" component={TermsAndConditions} layout={LayoutSubPages} />
          <AppRoute exact path="/refund-policy" component={RefundPolicy} layout={LayoutSubPages} />
          <AppRoute exact path="/contact-us" component={ContactUs} layout={LayoutSubPages} />
          <AppRoute exact path="/aboutus" component={AboutUs} layout={LayoutSubPages} />
        </Switch>
      )} />
  );
}

export default App;