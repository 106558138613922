import React from 'react'
import Footer from '../components/layout/Footer'
import HeaderSub from '../components/layout/HeaderSub'

export const LayoutSubPages = ({ children }) => {
  return (
    <>
      <HeaderSub navPosition="right" className="reveal-from-bottom" />
      <main className="site-content">
        {children}
      </main>
      <Footer />
    </>
  )
}
