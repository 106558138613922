import React, { useRef } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
// import Banner from '../components/layout/partials/Banner';

const LayoutDefault = ({ children }) => {
  const featureRef = useRef();
  const HelpRef = useRef();
  return <>
    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content" featureRef={featureRef} HelpRef={HelpRef}>
      {children}
    </main>
    {/* <Banner /> */}
    <Footer />
  </>
};

export default LayoutDefault;  