import React from "react";
import "./../styles/index.scss";

export const ContactUs = () => {
  return (
    <>
      <div className="contact-us-container container">
        <div className="contact-header">
          <h1>Contact Us</h1>
        </div>
        <div className="contact-info">
          <p>Write us at</p>
          <h6>info@banjararide.com</h6>
          <hr></hr>
          <p>Call us at</p>
          <h6>+91 9144748983</h6>
        </div>
      </div>
      <div className="office-address">
        <h6>Office Address</h6>
        <p>Petrol pump, Hig 3, LIG Sector-B, near Piplani, BHEL, Bhopal, Madhya Pradesh 462022</p>
      </div>
    </>
  );
};

