import React from 'react'

export const PrivacyPolicy = () => {
  return (
    <>
      <div className='container'>
        <div style={{ marginTop: '6rem' }}>
          <h1>Privacy Policy</h1>
          <p>Our Bike Rental App is committed to protecting your privacy. This policy explains how we collect, use, and share your personal information when you use our app. Please read this policy carefully to understand our practices.</p>
        </div>
        <div>
          <div>
            <h6>1. Data We Collect</h6>
            <p>We collect minimal data that is necessary for us to provide our services to you. The data we collect includes:</p>
            <ul>
              <li>Your name and email address</li>
              <li>
                Your phone number
              </li>
              <li>
                Your rental history
              </li>
              <li>
                Your location data
              </li>
            </ul>
            <p>We do not collect any sensitive personal information such as your credit card or bank account details.</p>
          </div>
          <div>
            <h6>2. How We Use Your Data</h6>
            <p>We use the data we collect to provide our services to you, including:</p>
            <ul>
              <li>Processing your rental requests</li>
              <li>
                Providing customer support
              </li>
              <li>
                Analyzing app usage and improving our services
              </li>
              <li>
                Communicating with you about updates, promotions, and other information related to our app
              </li>
            </ul>
            <p>We do not sell your data or share it with third-party advertisers or marketers. We may share your data with our trusted partners who assist us in providing our services to you, but only to the extent necessary for them to perform their functions.

            </p>
          </div>
          <div>
            <h6>3. Data Security</h6>
            <p>We take data security seriously and have implemented measures to ensure the safety of your data. We use industry-standard security measures to protect your data from unauthorized access, alteration, or destruction. We also restrict access to your data to only those employees or partners who need it to provide our services to you.</p>
          </div>
          <div>
            <h6>
              4. Data Retention

            </h6>
            <p>
              We retain your data only for as long as necessary to provide our services to you or as required by law. We may also retain your data for a reasonable period of time after you stop using our app to improve our services or for analytical purposes.
            </p>
          </div>
          <div>
            <h6>5. Your Rights</h6>
            <p>You have the right to access, correct, or delete your personal information that we have collected. You can do this by contacting us through the app. We will respond to your request as soon as possible and within the time limit set by applicable laws.</p>
          </div>
          <div>
            <h6>6. Updates to this Policy</h6>
            <p>We may update this privacy policy from time to time. We will notify you of any material changes to this policy by email or by posting a notice on our app. Your continued use of our app after any changes to this policy constitutes your acceptance of the changes.</p>
          </div>
        </div>
        <div>
          <p>If you have any questions or concerns about our privacy policy or our data practices, please contact us through the app or by email at info@banjararide.com.</p>
        </div>
      </div>
    </>
  )
}
