import React from 'react'

export const AboutUs = () => {
  return (
    <>
      <div className='container'>
        <div style={{ marginTop: '6rem' }}>
          <h1>Abouts US</h1>
        </div>
        <div>
          <p>Banjara Ride is Bhopal based Automobile Renting Enterprise where we provide Bike on rent and scooter on rent and traveling solutions from a wide range of Motorbikes and Scooters. Our aim is to become the one-stop solution for each and every travel-needed commodity. </p>
          <p>We provide Motorbikes or Scooters for rent for hours, days, weeks, and monthly basis. Customers get vehicles for their personal or professional use with no hectic charges and at a durable price. People like outsiders or students or any person which does not have any vehicle to travel and wants to save the fare of a taxi and do hassle-free riding so BanjaraRide is the best option. Eliminating high taxi charges. Travel frequently without any driver.</p>
        </div>
        <div>
          <p>
          Address: petrol pump, Hig 3, LIG Sector-B, near piplani, BHEL, Bhopal,
          Madhya Pradesh 462022
          </p>
        </div>
      </div>
    </>
  )
}
